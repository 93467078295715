<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/vrDetail', query: { id: item.id, type: item.type } })">
                <img class="active" :src="item.url" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { getVrList } from '@/api/index';
export default {
    name: 'Vr',
    data() {
        return {
            type: '',
            list: [],
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '总分馆',
                    to: { path: '/inCharge' },
                },
                {
                    title: 'VR展示'
                }
            ],
        };
    },
    watch: {
        "type": {
             handler(newVal, oldVal) {
                console.log('type' + newVal)
                console.log('type' + oldVal)
             },
             immediate:true
        }
    },
    created() {
        this.type = this.$route.query.type
        this.getVrList(this.$route.query.type);
        this.initNav(this.$route.query.type)
    },
    methods: {
        initNav(type) {
            if (type == '2') {
                this.nav = [
                    {
                        title: '首页',
                        to: { path: '/' },
                    },
                    {
                        title: '非遗文化',
                        to: { path: '/nonHeritage' },
                    },
                    {
                        title: 'VR展示',
                    }
                ]
            }
        },
        getVrList(type) {
            let data = {
                type: type
            };
            getVrList(data).then((res) => {
				this.list = this.list.concat(res.rows);
            });
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}

::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        position: relative;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }

            .butt {
                width: 100%;
                height: 44px;
                border-radius: 4px;
                color: #ffffff;
                font-size: 16px;
                line-height: 44px;
                text-align: center;
                margin-top: 24px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 10px;

                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
